import {
  FormControl as ChakraFormControl,
  Flex,
  FormControlOptions,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import { DatePicker } from '../DatePicker'
import { TimePicker } from '../DatePicker/TimePicker'

export const StyledFormInputControl = styled(ChakraFormControl)`
  .chakra-form__label {
    opacity: 1;
    color: #1a202c;
    font-size: 16px;
  }
`
export interface FormDatePickerControlIProps extends FormControlOptions {
  label?: string
  id?: string
  type?: string
  valid?: boolean
  errorMessage?: string
  leftIcon?: React.ElementType
  isInvalid?: boolean
  placeholder?: string
  rules?: {
    required?: boolean
  }
  showStartTimePicker?: boolean
  showEndTimePicker?: boolean
  selectedDate: Date

  selectedEndTime?: Date
  onChangeDate: (date: Date) => void
  onChangeEndTime?: (time: Date) => void
  [key: string]: unknown
}

export const FormDatePickerControl = ({
  id,
  label,
  isInvalid = false,
  errorMessage,
  placeholder,
  rules,
  showStartTimePicker,
  showEndTimePicker,
  selectedDate,
  selectedEndTime,
  onChangeDate,
  onChangeEndTime,
  ...rest
}: FormDatePickerControlIProps) => {
  return (
    <StyledFormInputControl id={id} isInvalid={isInvalid}>
      <FormLabel htmlFor={id} mr='0'>
        {label}
      </FormLabel>
      <Flex>
        <Flex flex={3} mr='20px'>
          <DatePicker
            date={selectedDate}
            name={id}
            onChange={onChangeDate}
            placeholder={placeholder}
            rules={rules}
            {...rest}
          />
        </Flex>
        {showStartTimePicker && (
          <Flex flex={1} mr='20px'>
            <StyledFormInputControl id={id} isInvalid={isInvalid}>
              <TimePicker
                date={selectedDate}
                maxTime={selectedEndTime}
                onChange={onChangeDate}
                time={selectedDate}
                {...rest}
              />
            </StyledFormInputControl>
          </Flex>
        )}
        {showEndTimePicker && (
          <Flex flex={1}>
            <StyledFormInputControl id={id} isInvalid={isInvalid}>
              <TimePicker
                minTime={selectedDate}
                onChange={onChangeEndTime}
                time={selectedEndTime}
                {...rest}
              />
            </StyledFormInputControl>
          </Flex>
        )}
      </Flex>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </StyledFormInputControl>
  )
}
